'use client';

/**
 * @see https://tanstack.com/query/latest/docs/framework/react/guides/advanced-ssr
 *
 * This is very useful for passing through dehydrated data that's later updated
 * again on the client side. For example, get initial data on fetch and then
 * re-query once an hour. Make sure to look through all the stuff with
 * streaming, it's complicated and there are a lot of possible footguns.
 *
 * This file can also be used to add any other client-side provider handling.
 */

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // With SSR, we usually want to set some default staleTime above 0 to
        // avoid refetching immediately on the client
        staleTime: 60 * 1000,
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient() {
  if (typeof window === 'undefined') {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one. This is
    // very important so we don't re-make a new client if React suspends during
    // the initial render. This may not be needed if we have a suspense
    // boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

export default function Providers({ children }) {
  // NOTE: Avoid useState when initializing the query client if you don't have
  //       a suspense boundary between this and the code that may suspend,
  //       because React will throw away the client on the initial render if it
  //       suspends and there is no boundary.
  const queryClient = getQueryClient();

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
